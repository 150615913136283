import React, { Suspense } from 'react';
import { BrowserRouter, Navigate, Routes, Route } from 'react-router-dom';
import Loading from './utils/Loading';
import ScrollToTop from './utils/ScrollToTop';
import MyErrorBoundary from './utils/MyErrorBoundary';

const Layout = React.lazy(() => import('./Layouts/Layout'));
const LayoutUnAuth = React.lazy(() => import('./Layouts/LayoutUnAuth'));
const LayoutAdmin = React.lazy(() => import('./Layouts/LayoutAdmin'));
const Main = React.lazy(() => import('./Main'));
const LiveQuizNew = React.lazy(() => import('./LiveQuiz/New'));
const LiveQuizMainDetail = React.lazy(() => import('./LiveQuiz/MainDetail'));
const Home = React.lazy(() => import('./Home'));
const A = React.lazy(() => import('./A'));
const NotFound = React.lazy(() => import('./NotFound/NotFound'));
const Login = React.lazy(() => import('./auth/Login'));
const Register = React.lazy(() => import('./auth/Register'));
const Forgot = React.lazy(() => import('./auth/Forgot'));
const Dashboard = React.lazy(() => import('./dashboard/Dashboard'));
const Quiz = React.lazy(() => import('./Quiz/Quiz'));
const Irac = React.lazy(() => import('./irac/Irac'));
const ListAllIrac = React.lazy(() => import('./irac/ListAllIrac'));
const Essays = React.lazy(() => import('./essays/Essays'));

// A Site
const Atimer = React.lazy(() => import('./A/utils/TimerCmp/Timer'));
const ATimerExample1 = React.lazy(() =>
  import('./A/components/TimerExamples/TimerExample1')
);
const ATimerExample2 = React.lazy(() =>
  import('./A/components/TimerExamples/TimerExample2')
);
const AQuizMaster = React.lazy(() => import('./A/components/quiz/Master'));
const AQuizDetail = React.lazy(() => import('./A/components/quiz/Detail'));
const AAdminNewQuiz = React.lazy(() => import('./A/admin/NewQuiz'));
const AAdmin = React.lazy(() => import('./A/admin/Admin'));

// Custom Tasks
const CustomTasksMain = React.lazy(() => import('./CustomTasks/Main'));
const CustomTasksSub = React.lazy(() => import('./CustomTasks/Sub'));
const CustomTasksItems = React.lazy(() => import('./CustomTasks/Items'));

function Routing() {
  return (
    <MyErrorBoundary>
      <Suspense fallback={<Loading />}>
        <BrowserRouter>
          <ScrollToTop>
            <Routes>
              <Route path="/a" element={<A />} />
              <Route path="/auth" element={<LayoutUnAuth />}>
                <Route path="login" element={<Login />} />
                <Route path="register" element={<Register />} />
                <Route path="forgot" element={<Forgot />} />
              </Route>
              <Route path="/admin" element={<LayoutAdmin />}>
                <Route path="" element={<AAdmin />} />
                <Route path="new/:id" element={<AAdminNewQuiz />} />
              </Route>
              <Route path="/" element={<Layout />}>
                <Route path="custom/tasks">
                  <Route path="main/:id" element={<CustomTasksMain />} />
                  <Route path="sub/:id" element={<CustomTasksSub />} />
                  <Route
                    path="items/:id/:task_id"
                    element={<CustomTasksItems />}
                  />
                </Route>
                <Route path="a">
                  <Route path="timer" element={<Atimer />} />
                  <Route path="timer/example1" element={<ATimerExample1 />} />
                  <Route path="timer/example2" element={<ATimerExample2 />} />
                  <Route path="quiz">
                    <Route path="master/:id" element={<AQuizMaster />} />
                    <Route
                      path="detail/:id/:testId/:pg"
                      element={<AQuizDetail />}
                    />
                    <Route
                      path="detail/:id/:testId"
                      element={<AQuizDetail />}
                    />
                  </Route>
                </Route>
                <Route path="main" element={<Main />} />
                <Route path="main/:id/:pg" element={<Main />} />
                <Route path="main/test/:id/:pg" element={<Main />} />
                <Route
                  path="main/detail/:testId/:id"
                  element={<LiveQuizMainDetail />}
                />
                <Route path="live/new/:id" element={<LiveQuizNew />} />
                <Route path="" element={<Home />} />
                <Route path="quiz/:testId/:id/:pg" element={<Quiz />} />
                <Route path="dashboard">
                  <Route path=":id/:pg" element={<Dashboard />} />
                  <Route path=":id" element={<Dashboard />} />
                </Route>
                <Route path="irac">
                  <Route path=":id/add" element={<Irac />} />
                  <Route path=":id/edit/:hypo_id" element={<Irac />} />
                  <Route path=":id/view/:pg" element={<ListAllIrac />} />
                  <Route path=":id/view" element={<ListAllIrac />} />
                </Route>

                <Route path="essays">
                  <Route path=":id" element={<Essays />} />
                  <Route path=":id/:subject/:topic" element={<Essays />} />
                </Route>
                <Route path="notfound" element={<NotFound />} />
                <Route path="*" element={<Navigate replace to="/notfound" />} />
              </Route>
            </Routes>
          </ScrollToTop>
        </BrowserRouter>
      </Suspense>
    </MyErrorBoundary>
  );
}

export default Routing;
