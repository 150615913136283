// import Parse from 'parse';
// import { editQuiz } from '../../api/quiz';
// import { percentage } from '../../utils/funcs';

import { parseCategories } from './funcs';

// Initial state values
const initialSummary = {
  category: {},
  topics: {},
  total: 0,
  scored: 0,
  percentage: 0,
  topicsArr: [],
  page: 0,
  result: []
};

const initialState = {
  quizSummary: JSON.parse(JSON.stringify(initialSummary)),
  quizSummaryLiveDefault: JSON.parse(JSON.stringify(initialSummary)),
  quizSummaryLive: {},
  categories: null,
  categoriesObj: null,
  testDetails: null,
  message: null,
  issues: {
    loading: false,
    error: null
  },
  error: null,
  mainDetails: {},
  a: {
    quiz: {
      active: {},
      closed: {},
      byId: {},
      usersInQuiz: {},
      myAnswers: {},
      summary: {}
    }
  }
};

// obj with key value pair
const obj = {
  SET_A_SUMMARY: (state, action) => {
    return {
      ...state,
      a: {
        ...state.a,
        quiz: {
          ...state.a.quiz,
          summary: {
            ...state.a.quiz.summary,
            ...action.payload
          }
        }
      }
    };
  },
  SET_A_ACTIVE_QUIZ: (state, action) => {
    return {
      ...state,
      a: {
        ...state.a,
        quiz: {
          ...state.a.quiz,
          active: {
            ...state.a.quiz.active,
            ...action.payload
          }
        }
      }
    };
  },
  SET_A_COMPLETED_QUIZ: (state, action) => {
    return {
      ...state,
      a: {
        ...state.a,
        quiz: {
          ...state.a.quiz,
          closed: {
            ...state.a.quiz.closed,
            ...action.payload
          }
        }
      }
    };
  },
  SET_A_MY_ANSWERS: (state, action) => {
    return {
      ...state,
      a: {
        ...state.a,
        quiz: {
          ...state.a.quiz,
          myAnswers: {
            ...state.a.quiz.myAnswers,
            ...action.payload
          }
        }
      }
    };
  },

  SET_A_DETAIL_QUIZ: (state, action) => {
    let obj = state.a.quiz.byId[action.payload.id] || {};
    obj = { ...obj, ...action.payload };

    return {
      ...state,
      a: {
        ...state.a,
        quiz: {
          ...state.a.quiz,
          byId: {
            ...state.a.quiz.byId,
            [action.payload.id]: obj
          }
        }
      }
    };
  },

  // A ends
  SET_QUIZ_MAIN_DETAILS: (state, action) => {
    return {
      ...state,
      mainDetails: {
        ...state.mainDetails,
        [action.payload.test_id]: {
          ...state.mainDetails[action.payload.test_id],
          ...action.payload
        }
      }
    };
  },
  SET_ERROR: (state, action) => {
    return {
      ...state,
      error: action.payload
    };
  },
  SET_CATEGORIES: (state, action) => {
    const obj = parseCategories(action.payload);
    return {
      ...state,
      categories: action.payload,
      categoriesObj: obj
    };
  },
  SET_QUIZ_RESET: (state) => {
    return {
      ...state,
      quizSummary: JSON.parse(JSON.stringify(initialSummary))
    };
  },
  UPDATE_QUIZ_SUMMARY: (state, action) => {
    return {
      ...state,
      quizSummary: action.payload || initialSummary
    };
  },
  UPDATE_TEST_DETAILS: (state, action) => {
    return {
      ...state,
      testDetails: action.payload
    };
  },
  SET_MESSAGE: (state, action) => {
    return {
      ...state,
      message: action.payload
    };
  },
  SET_QUIZ_SUMMARY_LIVE: (state, action) => {
    return {
      ...state,
      quizSummaryLive: {
        ...state.quizSummaryLive,
        [action.payload.test_id]: action.payload.summary
      }
    };
  },
  SET_QUIZ_SUMMARY: (state, action) => {
    /* const summary = state.quizSummary;
    if (!summary.category[action.payload.categoryId]) {
      summary.category[action.payload.categoryId] = {
        total: 0,
        score: 0,
        percentage: 0
      };
    }
    if (!summary.topics[action.payload.topic]) {
      summary.topics[action.payload.topic] = {
        total: 0,
        score: 0,
        percentage: 0
      };
    }
    summary.total = summary.total + 1;
    summary.scored = summary.scored + action.payload.score;
    summary.percentage = percentage(summary.scored, summary.total);
    summary.page = action.payload.page;

    summary.category[action.payload.categoryId] = {
      percentage: percentage(
        summary.category[action.payload.categoryId].score +
          action.payload.score,
        summary.category[action.payload.categoryId].total + 1
      ),
      total: summary.category[action.payload.categoryId].total + 1,
      score:
        summary.category[action.payload.categoryId].score + action.payload.score
    };

    summary.topics[action.payload.topic] = {
      percentage: percentage(
        summary.topics[action.payload.topic].score + action.payload.score,
        summary.topics[action.payload.topic].total + 1
      ),
      total: summary.topics[action.payload.topic].total + 1,
      score: summary.topics[action.payload.topic].score + action.payload.score
    };

    const arr = [];
    for (let key in summary.topics) {
      arr.push({ ...summary.topics[key], topic: key });
    }

    summary.topicsArr = arr;
    const item = action.payload.details;
    const user = Parse.User.current();
    editQuiz({
      user_id: user.id,
      test_id: item.testId,
      percentage: summary.percentage,
      scored: summary.scored,
      total: summary.total,
      question_id: item.id,
      answered: parseInt(item.selected, 10),
      is_correct: item.isCorrect ? 1 : 0,
      page: action.payload.page,
      summary
    })
      .then((r) => {
      })
      .catch((err) => {
        console.log('err is ', err.message);
      });

    return {
      ...state,
      quizSummary: summary
    }; */
    return {
      ...state,
      quizSummary: action.payload.summary,
      testDetails: action.payload.testDetails
    };
  },

  SET_ISSUES_ALL: (state, action) => {
    return {
      ...state,
      issues: { ...state.issues, ...action.payload }
    };
  }
};

// action = {type: 'SET_NAME', payload: {}}
const SitesReducer = (state = initialState, action = {}) => {
  if (obj[action.type]) {
    return obj[action.type](state, action);
  }
  return state;
};

export default SitesReducer;
